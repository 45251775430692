import classNames from 'classnames';
import { FC } from 'react';

export const ScoreToPar: FC<{ score?: number }> = ({ score }) => {
  if (typeof score === 'number') {
    const className = classNames(
      'w-8 h-8 text-center inline-flex justify-center rounded-full text-white text-xs',
      {
        'bg-bogey-blue-legacy': score === 0,
        'bg-birdie-red-legacy': score < 0,
        'bg-over-par-gray-legacy': score > 0,
      }
    );

    if (score > 10000) {
      return (
        <div className={className}>
          <div className="self-center">N/A</div>
        </div>
      );
    }

    return (
      <div className={className}>
        <div className="self-center">{score === 0 ? 'E' : `${score > 0 ? '+' : ''}${score}`}</div>
      </div>
    );
  } else {
    return '-';
  }
};
