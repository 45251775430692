'use client';

import { RoundAnalytics } from '@spikemark/rest-api';
import { MascotWithName } from '@spikemark/ui/components/mascot-with-name';
import { MatchplayLeaderboardItem } from '@spikemark/ui/types/leaderboard';
import { PlayerLink, TeamLink } from '@spikemark/ui/components/navigation/entity-links';
import { PositionDeltaDisplay } from '@spikemark/ui/components/position-delta-display';
import { ScoreToPar } from '@spikemark/ui/components/score-to-par';
import { Table } from '@spikemark/ui/components/table/table';
import { displayUsername, formatThru } from '@spikemark/ui/utils/format';
import { scoreFormat } from '@spikemark/ui/utils/score';
import { FC } from 'react';

export type PreviewLeaderboardProps = {
  type: 'Team' | 'Player';
  tournamentId: string;
};

export const PreviewStrokeplayLeaderboardGrid: FC<{
  data: RoundAnalytics[];
  type: PreviewLeaderboardProps['type'];
}> = ({ data, type }) => {
  return (
    <Table<RoundAnalytics>
      mode="auto"
      compact
      className="w-full text-xs 2xl:text-sm"
      columns={[
        { key: 'currentRank', cellClassName: 'numeric' },
        {
          header: '+/-',
          key: 'rankDelta',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => <PositionDeltaDisplay change={rowData.rankDelta ?? 0} />,
        },
        {
          header: 'Team',
          cellClassName: 'max-w-[150px]',
          cellRenderer: ({ rowData }) =>
            type === 'Team' ? (
              <TeamLink schoolId={rowData.schoolId}>
                <MascotWithName
                  alt={rowData.schoolName}
                  path={rowData.schoolLogo}
                  variant="small"
                  displayMode="team"
                  className="whitespace-break-spaces"
                >
                  {rowData.schoolName}
                </MascotWithName>
              </TeamLink>
            ) : (
              <PlayerLink playerId={rowData.playerId}>
                <MascotWithName
                  alt={rowData.schoolName}
                  path={rowData.schoolLogo}
                  variant="small"
                  displayMode="team"
                  className="whitespace-break-spaces"
                >
                  {displayUsername(rowData.playerName, rowData.playerType)}
                </MascotWithName>
              </PlayerLink>
            ),
          width: '90%',
        },
        {
          header: 'Total',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => <ScoreToPar score={rowData.totalScore} />,
        },
        {
          header: 'Thru',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => formatThru(rowData.holeThrough ?? 18),
        },
        {
          header: 'Today',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => scoreFormat(rowData.scores?.[rowData.scores?.length - 1]),
        },
      ]}
      data={data?.slice(0, 7) ?? []}
      emptyMessage="No leaderboard data available"
      rowKey={({ rowData }) =>
        type === 'Player' ? rowData.playerId! : `${rowData.schoolId}-${rowData.teamLabel ?? ''}`
      }
      headerRowClassName="shadow-sm light:bg-white"
      rowClassName="h-[3rem] align-middle"
    />
  );
};

export const PreviewMatchplayLeaderboardGrid: FC<{
  data?: MatchplayLeaderboardItem[];
}> = ({ data }) => {
  if (!data || data?.length === 0) {
    return <div className="px-4 py-2 text-center">No leaderboard data available</div>;
  }

  return (
    <Table<MatchplayLeaderboardItem>
      mode="auto"
      compact
      className="w-full text-xs 2xl:text-sm"
      columns={[
        { key: 'position', cellClassName: 'numeric' },
        {
          header: 'Team',
          cellClassName: 'max-w-[150px]',
          cellRenderer: ({ rowData }) => (
            <TeamLink schoolId={rowData.id}>
              <MascotWithName
                alt={rowData.displayName}
                path={rowData.schoolLogo}
                variant="small"
                displayMode="team"
                className="whitespace-break-spaces"
              >
                {rowData.displayName}
              </MascotWithName>
            </TeamLink>
          ),
          width: '85%',
        },
        {
          header: 'W',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => rowData.record.wins,
          width: '5%',
        },
        {
          header: 'L',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => rowData.record.losses,
          width: '5%',
        },
        {
          header: 'T',
          cellClassName: 'numeric',
          cellRenderer: ({ rowData }) => rowData.record.draws,
          width: '5%',
        },
      ]}
      data={data.sort((a, b) => (a?.position || 0) - (b?.position || 0)).slice(0, 7)}
      emptyMessage="No leaderboard data available"
      rowKey={({ rowData }) => rowData.displayName}
      headerRowClassName="shadow-sm light:bg-white"
      rowClassName="h-[3rem] align-middle"
    />
  );
};
