'use client';

import { PreviewLeaderboardProps } from '@/components/tables/leaderboard/preview/preview-leaderboard';
import { Tournament } from '@spikemark/rest-api';
import { TabsContent } from '@spikemark/ui/components/ui/tabs';
import { FC } from 'react';
import { useInView } from 'react-intersection-observer';

type TournamentPreviewBodyProps = {
  tournament: Tournament;
  leaderboardComponent: FC<PreviewLeaderboardProps>;
};

export const TournamentPreviewBody: FC<TournamentPreviewBodyProps> = ({
  leaderboardComponent: LeaderboardComponent,
  tournament,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      <TabsContent value="Player">
        <LeaderboardComponent
          type="Player"
          isInView={inView}
          tournamentId={tournament.tournamentId}
          format={tournament.eventType}
        />
      </TabsContent>
      <TabsContent value="Team">
        <LeaderboardComponent
          type={'Team'}
          isInView={inView}
          tournamentId={tournament.tournamentId}
          format={tournament.eventType}
        />
      </TabsContent>
    </div>
  );
};
